.root {
  overflow: hidden;
}

#headlessui-portal-root {
  @apply text-neutral-900 dark:text-neutral-200 text-base;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.z-max {
  z-index: 999999999;
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-8;
}

.nc-hero-field-padding--small {
  @apply py-2 px-4 md:py-2 md:px-7 xl:px-8;
}

.nc-hero-field-focused {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ListingDetailPage {
  .nc-hero-field-focused {
    @apply rounded-3xl shadow-2xl;
  }
  &.nc-ListingExperiencesDetailPage {
    .ExperiencesDateSingleInput .nc-hero-field-focused {
      @apply rounded-r-none;
    }
    .listingExperiencesDetailPage__GuestsInput .nc-hero-field-focused {
      @apply rounded-l-none;
    }
  }

  .DateRangePickerInput_clearDates,
  .SingleDatePickerInput_clearDate {
    @apply rounded-full bg-neutral-200/90 dark:bg-white/10;
    svg {
      @apply w-2 fill-neutral-700 dark:fill-neutral-300;
    }
  }
}

.nc-ListingStayDetailPage__guestsInput {
  .nc-hero-field-focused {
    @apply rounded-t-none;
  }
}
.nc-ListingStayDetailPage__stayDatesRangeInput {
  .nc-hero-field-focused {
    @apply rounded-b-none;
  }
}

.nc-hero-field-focused--2 {
  @apply rounded-full dark:bg-white/5;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-7;
}

// LISTING PAGE DETAIL
.listingSectionSidebar__wrap {
  @apply w-full flex flex-col rounded-2xl border-b border-t border-l border-r border-neutral-200 dark:border-neutral-700 space-y-6 xl:space-y-7 pb-10 p-2 sm:p-4 xl:px-8 xl:py-6;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}

//
.nc-PropertyCardH {
  .nc-BtnLikeIcon--liked {
    @apply text-red-500 dark:text-red-500;
  }
}

//
.ncSectionLogos {
  img {
    @apply w-full;
    max-width: 200px;
  }
}

//
.modalPhotos-single-gallery {
  .glide__bullet--active {
    @apply block;
  }
}

.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

.FooterNav--hide {
  @apply translate-y-full;
}

@keyframes myblur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nc-custom-shadow-1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.boxShadow{
    margin:  1.25rem 1.25rem 1.25rem 0rem !important;
    background: rgb(255, 255, 255);
    z-index: 6;
    display: flex;
    box-shadow: rgba(28, 28, 28, 0.098) 0px -1px 3px -3px, rgba(28, 28, 28, 0.098) 3px 0px 3px -3px, rgba(28, 28, 28, 0.098) 0px 5px 5px -3px;
    flex-direction: column;
    margin-bottom: 1.5rem;
    border-radius:1rem 1rem 1rem 1rem;
}

.property-listings-map-container {
  display: flex;
  height: 100vh;
}

.hidden-card {
  height: 7.25rem;
    z-index: 2;
    position: fixed;
    top: 2.375rem;
    width: 921px;
    background:#417657;
    // background: rgb(232, 238, 237);
}

.scro::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.mapPosition{
  z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0px;
    border-width: 0px;
    margin: 0px;
    left: 0px;
    top: 0px;
    touch-action: pan-x pan-y;
}

/* Card starts at the bottom */
.hidden-card1 {
  position: fixed;
  bottom: 80px;
  left: 0;
  width: 100%;
  height: 300px; /* Set the desired height for the card */
  background-color: white;
  transform: translateY(100%); /* Start hidden, off-screen */
  transition: transform 0.3s ease-in-out;
  z-index: 100; /* Ensure the card is on top */
  border-radius: 16px 16px 0 0; /* Rounded top corners */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2); /* Shadow for effect */
}

/* When the card is visible (dragged up) */
.visible-card {
  transform: translateY(0); /* Move the card to the visible position */
}


.cards-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory; /* Enable scroll snapping */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on mobile devices */
  scroll-behavior: smooth; /* Smooth scrolling between cards */
}

/* Individual card */
.card {
  scroll-snap-align: start; /* Snap card to start of the container */
  flex: none; /* Ensure cards don't shrink */
  height: 100vh; /* Fullscreen height for each card */
  background-color: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}


.graphCard {
  position: absolute;
  top: 0rem;
  left: 0px;
  width: 100%;
  height: 8.625rem;
  background: linear-gradient(rgb(232, 238, 237) 0%, rgba(232, 238, 237, 0) 100%);
  z-index: 1;
  pointer-events: none;
}
.graphcard-container{
  display: flex;
  padding-top: 1.25rem;
  position: sticky;
  top: 5.5rem;
  height: 100%;
  min-width: 30.5rem;
  flex: 1 1 0%;
}

.mapEndLine{
  position: absolute;
  bottom: 0rem;
  left: 0px;
  width: 100%;
  height: 4.5rem;
  background: linear-gradient(0deg, rgb(232, 238, 237) 0%, rgba(232, 238, 237, 0) 100%);
  z-index: 1;
  pointer-events: none;
}

.mappoint{
  padding: 7px 15px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 25px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 8px 17px rgba(0, 0, 0, 0.2);
}

.my-map {
  outline: none; /* Remove the outline */
  border: none; /* Remove any border */
}
/* Add this CSS to your stylesheet */
.map-container div {
  border:none !important;
}

.sticky-title {
  position: -webkit-sticky; /* For Safari support */
  position: sticky;
  top: 0;
  background-color: white; /* Ensure title is visible over content */
  padding: 10px 0;         /* Optional padding */
  z-index: 10;             /* Keeps title above other elements */
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1); /* Optional shadow effect */
}


.darkTitleForHeader {
  font-weight: 500 !important;
  text-transform: capitalize !important;
  font-family: Google Sans, Helvetica Neue, sans-serif !important;
  background: linear-gradient(90deg, rgb(40 97 113), rgb(0 162 208)) !important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  letter-spacing: -0.5px !important;
}

.darkTitleForHeader1 {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-family: Google Sans, Helvetica Neue, sans-serif !important;
  background:  #0c586e!important;
  -webkit-background-clip: text !important;
  color: transparent !important;
  letter-spacing: -0.5px !important;
}
// .darkTitleForHeader1 {
//   font-weight: 500 !important;
//   text-transform: capitalize !important;
//   font-family: Google Sans, Helvetica Neue, sans-serif !important;
//   background: linear-gradient(90deg, rgb(40 97 113), rgb(0 162 208)) !important;
//   -webkit-background-clip: text !important;
//   color: transparent !important;
//   letter-spacing: -0.5px !important;
// }

.skeleton-loader {
  background: #e0e0e0;
  height: 350px;
  width: 100%;
}